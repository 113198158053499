<template>
    <component :is="isMobile?cpnMobile:cpnDesktop"/>
</template>
<script setup>
    const { isMobile,isDesktop } = useDevice();
     
    definePageMeta({
        layout: "default",

    });
</script>
<script>

    import cpnDesktop from "./desktop.vue";
    import cpnMobile from "./mobile.vue";
    export default {
        name: "mobile",
        components: {
            cpnDesktop,
            cpnMobile,
        },
    }
</script>
